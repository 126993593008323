<!--
 * @Descripttion:
 * @version:
 * @Author: wenboliu
 * @Date: 2023-06-17 15:33:43
 * @LastEditors: Andy
 * @LastEditTime: 2023-11-15 15:08:55
-->
<template>
  <div class="common-title-container">
    <p :style="{'--bgColor' : bgColor}" class="common-title">{{ title }}</p>
    <slot />
  </div>
</template>

<script>
import bus from '../../utils/bus'
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      bgColor: this.$store.state.settings.theme
    }
  },
  created () {
    bus.$on('theme', (msg) => {
      this.bgColor = msg
    })
  }
}
</script>

<style lang='scss' scoped>
.common-title-container {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: rgba(0,0,0,0.9);
}
.common-title{
  display: flex;
  justify-content: left;
  align-items: center;
  height: 15px;
}
.common-title::before{
  content: "";
  display: block;
  width: 3px;
  height: 14px;
  background-color: var(--bgColor)!important;
  margin-right: 5px;
}
</style>
