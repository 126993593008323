<template>
  <div class="container-warp">
    <div class="top-box">
      <!-- <back-title>课程名称</back-title> -->
      <div class="return-title">
          <div class="back-btn" @click="_backBtn"><i class="el-icon-back mr10"></i>返回</div>
          <span class="division">|</span>
          <div class="back-title">课程名称</div>
        </div>
      <div class="top-box-info">
        <div class="top-box-info-left">
          <img v-if="!detailInfo.cover" src="@/assets/img/Course.png" alt="">
              <img v-else :src="detailInfo.cover" alt="" />
          <div class="top-box-info-left-xx">
            <div class="top-box-info-left-bt">
              {{detailInfo.courseName}}<span v-if="detailInfo.courseType">{{detailInfo.courseType}}</span>
            </div>
            <div class="top-box-info-left-kc">{{detailInfo.description}}</div>
          </div>
        </div>
        <div class="top-box-info-right">
          <el-button
          v-if="detailInfo.areLearning==2"
          class="add-group-btn"
          :style="{'--lock-btn': $store.state.settings.theme}"
          @click="startStudy"
          >开始学习</el-button>
        </div>
      </div>
      <div class="course-introduce-content">
        <com-title>课程介绍内容</com-title>
        <div class="conetnt-item" v-html="detailInfo.introduce"></div>
      </div>
    </div>
    <el-dialog
    title="提示"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="315px"
    :before-close="handleClose">
    <div slot="title" class="header-title">
      <svg
        t="1700015762243"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="3163"
        width="19.2"
        height="19.2"
      >
        <path
          d="M512 1024A512 512 0 1 0 512 0a512 512 0 0 0 0 1024zM460.8 278.755556a51.2 51.2 0 1 1 102.4 0v295.822222a51.2 51.2 0 1 1-102.4 0V278.755556z m102.4 455.111111a51.2 51.2 0 1 1-102.4 0 51.2 51.2 0 0 1 102.4 0z"
          fill="#FAAD14"
          p-id="3164"
        ></path>
      </svg>
      <span>确认开始学习</span>
    </div>
    <span>开始学习后，系统将自动把当前课程添加到【正在学习】列表，以方便您查看学习</span>
    <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="defineStudy">确 定</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
// import BackTitle from '@/components/backTitle/index.vue'
import comTitle from '@/components/comTitle/index.vue'
import { getCourseDetailApi } from '@/api/course'
import { addBeLearningApi } from '@/api/study'
export default {
  components: {
    // BackTitle,
    comTitle
  },
  data () {
    return {
      dialogVisible: false,
      detailInfo: {}
    }
  },
  created () {
    this.getCourseDetail()
  },
  methods: {
    getCourseDetail () {
      getCourseDetailApi({
        id: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.detailInfo = res.data
        }
      })
    },
    handleClose () {
      this.dialogVisible = false
    },
    startStudy () {
      // 开始学习
      this.dialogVisible = true
    },
    defineStudy () {
      addBeLearningApi({
        courseId: this.$route.query.id
      }).then(res => {
        if (res.code === 200) {
          this.$message.success('添加成功！')
          this.dialogVisible = false
          this.$router.push({
            path: '/onlineCourseDetail',
            query: {
              id: this.$route.query.id,
              learningCourseId: res.data.learningCourseId,
              pageValue: 'onlineCourse'
            }
          })
        }
      })
    },
    _backBtn () {
      this.$router.push({
        path: '/studentOnlineCourse'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.top-box {
  padding: 16px 24px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  .top-box-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .top-box-info-left {
      display: flex;
      img {
        margin-right: 10px;
        width: 120px;
        height: 90px;
        border-radius: 4px;
      }
      .top-box-info-left-xx {
        .top-box-info-left-bt {
          margin: 4px 0;
          font-size: 16px;
          color: #333333;
          font-weight: 500;
          span {
            display: inline-block;
            padding: 3px 12px;
            margin-left: 16px;
            font-size: 12px;
            color: #ffffff;
            border-radius: 8px 0px 8px 0px;
            background: linear-gradient(to right, #31adf6, #5096ff);
          }
        }
        .top-box-info-left-kc {
          margin-top: 16px;
          font-size: 14px;
          color: #666666;
        }
        .top-box-info-left-mc {
          margin-left: 8px;
          font-size: 12px;
          color: #979797;
        }
      }
    }
  }
}
.add-group-btn {
  width: 88px;
  border: 1px solid #0089ff;
  font-size: 14px;
  color: #0089ff;
}
.course-introduce-content{
    min-height: 620px;
    background: #FFFFFF;
    padding-top: 24px;
    .conetnt-item{
        font-size: 14px;
        color: #4D4D4D;
        margin-top: 8px;
    }
}
/deep/ .header-title{
    display: flex;
    align-items: center;
    svg{
        margin-right: 10px;
    }
}
/deep/.el-dialog__body{
    padding:10px 28px 24px 52px !important;
}
// 头部返回标题样式
.return-title{
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  .back-btn{
    font-size: 14px;
    color: #303133;
  }
  .division{
    color: #F3F3F3;
    width: 1px;
    background-color: #F3F3F3;
    border-radius: 2px;
    margin: 0px 16px;
  }
  .back-title{
    font-size: 16px;
    color: #333333;
  }
}
</style>
